@-webkit-keyframes spinner-rotation {
	from {
		-webkit-transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(359deg);
	}
}

@-moz-keyframes spinner-rotation {
	from {
		-moz-transform: rotate(0deg);
	}
	to {
		-moz-transform: rotate(359deg);
	}
}

@-o-keyframes spinner-rotation {
	from {
		-o-transform: rotate(0deg);
	}
	to {
		-o-transform: rotate(359deg);
	}
}

@keyframes spinner-rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}

/* line 111, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player {
	position: relative;
	display: block;
	overflow: hidden;
	z-index: 1;
}

/* line 118, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__overlay {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 7;
	text-align: center;
	width: inherit;
}

/* line 131, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player[data-running="loading"] .vod-player--hide-on-loading,
.vod-player[data-running="true"] .vod-player--hide-on-playing {
	display: none;
}

/* line 136, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__overlay a {
	left: 50%;
	padding: 10px 20px;
	color: rgb(var(--color-global-positive));
	text-shadow: 0 1px #333;
	background-color: rgba(0, 0, 0, 0.3);
	text-decoration: none;
	font-size: 12px;
	position: absolute;
	top: 50%;
	opacity: 0;
	transform: translateY(-50%) translateX(-50%);
	transition: all .3s ease;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__overlay a {
	left: auto;
	right: 50%;
}

/* line 152, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__overlay:hover a {
	transform: translateY(-50%) translateX(-50%);
	opacity: 1;
}

/* line 158, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__header {
	text-align: left;
	position: absolute;
	z-index: 4;
	padding: 10px;
	margin: 0;
	font-size: 12px;
	background-color: rgba(0, 0, 0, 0.7);
	color: rgb(var(--color-global-positive));
	opacity: 0;
	box-sizing: border-box;
	transform: translateY(-100%);
	transition: all .6s ease;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__header {
	text-align: right;
}

/* line 174, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__header.is-visible,
.vod-player[data-running="false"] .vod-player__header {
	opacity: 1;
	transform: translateY(0%);
}

/* line 180, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__header,
.vod-player__header b {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* line 188, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__header br {
	display: none;
}

/* line 194, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__control-bar {
	display: block;
	position: absolute;
	z-index: 8;
	bottom: 0;
	width: 100%;
	margin: 0;
	padding: 0;
	font-size: 10px;
	transform: translateY(100%);
	transition: all .6s ease;
}

/* line 207, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__control-bar.is-visible,
.vod-player[data-running="false"] .vod-player__control-bar {
	transform: translateY(0%);
}

/* line 212, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__control-bar > li {
	float: left;
	list-style: none;
	padding: 0;
	color: rgb(var(--color-global-border));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__control-bar > li {
	float: right;
}

/* line 219, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player .vod-player__btn {
	min-width: 25px;
	height: 20px;
}

/* line 224, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player .vod-player__btn:hover {
	cursor: pointer;
}

/* line 229, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player .vod-player__btn.btn--play::before {
	border-left: 12px solid;
	margin: 4px 0 0 7px;
	content: "";
	display: inline-block;
	vertical-align: middle;
	width: 0;
	height: 0;
	border-top: 6px solid transparent;
	border-bottom: 6px solid transparent;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player .vod-player__btn.btn--play::before {
	border-left: 0;
	border-right: 12px solid;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player .vod-player__btn.btn--play::before {
	margin: 4px 7px 0 0;
}

/* line 241, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player[data-running="true"] .vod-player__btn.btn--play::before,
.vod-player[data-running="true"] .vod-player__btn.btn--play::after {
	margin: 4px 0 0 7px;
	width: 3px;
	height: 12px;
	border: 0;
	content: "";
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player[data-running="true"] .vod-player__btn.btn--play::before, .is-rtl
.vod-player[data-running="true"] .vod-player__btn.btn--play::after {
	margin: 4px 7px 0 0;
}

/* line 250, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player[data-running="true"] .vod-player__btn.btn--play::before {
	margin: 4px 0 0 7px;
	border: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player[data-running="true"] .vod-player__btn.btn--play::before {
	margin: 4px 7px 0 0;
}

/* line 255, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player[data-running="true"] .vod-player__btn.btn--play::after {
	margin: 4px 0 0 3px;
	content: "";
	display: inline-block;
	vertical-align: middle;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player[data-running="true"] .vod-player__btn.btn--play::after {
	margin: 4px 3px 0 0;
}

/* line 264, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player .vod-player__btn.btn--sound {
	position: relative;
	z-index: 1;
	overflow: hidden;
}

/* line 270, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player .vod-player__btn.btn--sound::before {
	border-radius: 0 15px 0 0;
	left: 14px;
	content: "";
	position: absolute;
	top: 50%;
	width: 6px;
	height: 6px;
	margin-top: -5px;
	border: 4px double;
	border-width: 4px 4px 0 0;
	transform: rotate(45deg);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player .vod-player__btn.btn--sound::before {
	border-radius: 15px 0 0 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player .vod-player__btn.btn--sound::before {
	left: auto;
	right: 14px;
}

/* line 284, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player .vod-player__btn.btn--sound span {
	padding-left: 28px;
	display: block;
	border: 0;
	color: #dd4c3b;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player .vod-player__btn.btn--sound span {
	padding-left: initial;
	padding-right: 28px;
}

/* line 291, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player .vod-player__btn.btn--sound span::before {
	left: 0;
	content: "";
	position: absolute;
	top: 50%;
	border: 7px solid transparent;
	margin-top: -7px;
	background: transparent;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player .vod-player__btn.btn--sound span::before {
	left: auto;
	right: 0;
}

/* line 301, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player .vod-player__btn.btn--sound span::after {
	left: 6px;
	content: "";
	position: absolute;
	top: 50%;
	width: 4px;
	height: 6px;
	margin-top: -3px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player .vod-player__btn.btn--sound span::after {
	left: auto;
	right: 6px;
}

/* line 313, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.is-mute .vod-player__btn.btn--sound::before {
	left: 2px;
	border-radius: 16px;
	width: 16px;
	height: 16px;
	margin-top: -10px;
	border: 1px solid;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player.is-mute .vod-player__btn.btn--sound::before {
	left: auto;
	right: 2px;
}

/* line 322, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.is-mute .vod-player__btn.btn--sound::after {
	left: 2px;
	content: "";
	position: absolute;
	top: 50%;
	width: 18px;
	border-top: 1px solid;
	border-bottom: 1px solid;
	transform: rotate(-45deg);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player.is-mute .vod-player__btn.btn--sound::after {
	left: auto;
	right: 2px;
}

/* line 334, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__cover {
	display: block;
	position: absolute;
	z-index: 2;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	opacity: 1;
	top: 0;
	transition: all 1s ease;
}

/* line 347, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player[data-running="loading"] .vod-player__cover {
	opacity: 0;
}

/* line 352, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__slides {
	text-align: left;
	opacity: 1;
	display: block;
	position: absolute;
	overflow: hidden;
	width: 100%;
	height: 100%;
	margin: 0;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__slides {
	text-align: right;
}

/* line 363, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__slide {
	width: 100%;
	height: 100%;
	margin: 0;
	position: absolute;
	opacity: 0;
	background-size: cover;
	background-position: center center;
}

/* line 373, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__slides > img {
	position: absolute;
	opacity: 0;
	animation-play-state: paused !important;
}

/* line 380, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player[data-running="true"] .vod-player__slides > img {
	animation-play-state: running !important;
}

/* line 385, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player-is-ios.vod-player[data-running="false"] .vod-player__slides > img {
	animation: none !important;
	width: 100% !important;
	height: 100% !important;
	margin: 0 !important;
	object-fit: cover;
}

/* line 395, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang {
	float: right;
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__btn.btn--lang {
	float: left;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__btn.btn--lang {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .vod-player__btn.btn--lang {
		margin-right: 0;
	}
}

/* line 400, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang__current {
	padding-left: 10px;
	line-height: 20px;
	cursor: pointer;
	position: relative;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__btn.btn--lang .lang__current {
	padding-left: initial;
	padding-right: 10px;
}

/* line 407, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang__current::before,
.vod-player__btn.btn--lang .lang__current::after {
	left: 0;
	content: "";
	position: absolute;
	top: 50%;
	border-width: 2.5px;
	border-style: solid;
	border-color: transparent;
	background: transparent;
	transform: rotate(45deg);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__btn.btn--lang .lang__current::before, .is-rtl
.vod-player__btn.btn--lang .lang__current::after {
	left: auto;
	right: 0;
}

/* line 420, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang__current::before {
	margin-top: -3px;
}

/* line 424, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang__current::after {
	margin-top: -1px;
}

/* line 428, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang__name {
	margin-right: 5px;
	text-transform: capitalize;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__btn.btn--lang .lang__name {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .vod-player__btn.btn--lang .lang__name {
		margin-right: 0;
	}
}

/* line 433, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__lang-selector {
	right: 5px;
	margin: 0;
	padding: 0;
	list-style: none;
	position: absolute;
	bottom: 20px;
	width: 100px;
	opacity: 0;
	transform: translateX(110%);
	transition: all .6s ease;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__lang-selector {
	right: auto;
	left: 5px;
}

/* line 447, scss/80-themes/Saunders/60-components/vod-player.scss */
.lang__toggle {
	display: none;
}

/* line 451, scss/80-themes/Saunders/60-components/vod-player.scss */
.lang__toggle:checked + .vod-player__lang-selector {
	opacity: 1;
	transform: translateX(0%);
}

/* line 456, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__lang-selector > li {
	display: block;
	padding: 4px 10px;
	text-transform: capitalize;
}

/* line 463, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang__flag {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	height: 12px;
	width: 16px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

/* line 474, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__lang-selector .lang__flag {
	margin-right: 5px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__lang-selector .lang__flag {
	margin-right: initial;
	margin-left: 5px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .vod-player__lang-selector .lang__flag {
		margin-right: 0;
	}
}

/* line 478, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang--eng {
	background-image: url("<%=resources_server%>layouts/common/images/vod/flags/us.svg");
}

/* line 482, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang--fra {
	background-image: url("<%=resources_server%>layouts/common/images/vod/flags/fr.svg");
}

/* line 486, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang--zht {
	background-image: url("<%=resources_server%>layouts/common/images/vod/flags/cn.svg");
}

/* line 490, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang--jpn {
	background-image: url("<%=resources_server%>layouts/common/images/vod/flags/jp.svg");
}

/* line 494, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang--kor {
	background-image: url("<%=resources_server%>layouts/common/images/vod/flags/kr.svg");
}

/* line 498, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__btn.btn--lang .lang--spa {
	background-image: url("<%=resources_server%>layouts/common/images/vod/flags/es.svg");
}

/* line 504, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent {
	left: 0;
	padding: 10px;
	font-size: 12px;
	line-height: 1.5;
	text-shadow: 1px 1px #333;
	width: 100%;
	color: rgb(var(--color-global-positive));
	background-color: rgba(0, 0, 0, 0.7);
	background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0.7) 30%, transparent 100%);
	position: absolute;
	z-index: 8;
	bottom: 20px;
	transform: translateX(-100%);
	transition: all .6s ease;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__agent {
	left: auto;
	right: 0;
}

/* line 523, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent a,
.vod-player__agent .o-phone-number:hover {
	display: block;
	color: rgb(var(--color-global-positive));
	opacity: 1;
}

/* line 530, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent a {
	transition: all .6s ease;
}

/* line 533, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent a:hover {
	opacity: 1;
}

/* line 538, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .o-phone-number {
	line-height: 2em;
}

/* line 543, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent.is-visible {
	transform: translateX(0%);
}

/* line 547, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__photo,
.vod-player__agent .agent__data {
	display: inline-block;
	vertical-align: top;
}

/* line 553, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__photo {
	margin-right: 10px;
	width: 45px;
	height: 60px;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__agent .agent__photo {
	margin-right: initial;
	margin-left: 10px;
}

@media screen and (min-width: 0\0) {
	/* line 7, scss/20-tools/_mixins.scss */
	.is-rtl .vod-player__agent .agent__photo {
		margin-right: 0;
	}
}

/* line 559, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__data {
	max-width: calc(100% - 60px);
}

/* line 563, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__name {
	font-weight: bold;
	font-size: 14px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* line 571, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__icon {
	display: inline-block;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	overflow: hidden;
	list-style: none;
	padding: 0;
	margin: 0 0 0.25em;
	height: 18px;
}

/* line 583, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__icon::before,
.vod-player__agent .agent__icon::after,
.vod-player__agent .agent__icon span::before,
.vod-player__agent .agent__icon span::after {
	left: 0;
	content: "";
	position: absolute;
	top: 50%;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__agent .agent__icon::before, .is-rtl
.vod-player__agent .agent__icon::after, .is-rtl
.vod-player__agent .agent__icon span::before, .is-rtl
.vod-player__agent .agent__icon span::after {
	left: auto;
	right: 0;
}

/* line 593, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__icon span::before,
.vod-player__agent .agent__icon span::after {
	margin: -7px 0 0;
	background: #fff;
}

/* line 599, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__icon span {
	padding-left: 25px;
	display: block;
	border: 0;
	color: rgb(var(--color-global-positive));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__agent .agent__icon span {
	padding-left: initial;
	padding-right: 25px;
}

/* line 606, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__phone .agent__icon span::before {
	left: 5px;
	width: 4px;
	height: 8px;
	border-width: 5px 0 5px 2px;
	border-style: solid;
	background: transparent;
	transform: rotate(-30deg);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__agent .agent__phone .agent__icon span::before {
	left: auto;
	right: 5px;
}

/* line 616, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__mail .agent__icon::before {
	left: 5px;
	z-index: 6;
	top: 0;
	width: 10px;
	height: 10px;
	border-width: 0 1px 1px 0;
	border-style: solid;
	border-color: #333;
	background: transparent;
	transform: rotate(39deg) skew(-12deg);
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__agent .agent__mail .agent__icon::before {
	left: auto;
	right: 5px;
}

/* line 629, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__mail .agent__icon span::before {
	left: 3px;
	width: 5px;
	border-width: 5px;
	border-style: solid;
	border-color: #fff #fff transparent;
	margin-top: -3px;
	background: transparent;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__agent .agent__mail .agent__icon span::before {
	left: auto;
	right: 3px;
}

/* line 639, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player__agent .agent__mail .agent__icon span::after {
	left: 4px;
	top: 17px;
	border-width: 0 8px 5px;
	border-style: solid;
	border-color: #fff transparent;
	background: transparent;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player__agent .agent__mail .agent__icon span::after {
	left: auto;
	right: 4px;
}

/* line 650, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player--responsive .vod-player__header {
	position: absolute;
	width: 100%;
	top: 0;
}

/* line 656, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player--responsive .vod-player__slides {
	width: auto;
	height: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform: rotate(0.001deg);
}

/* line 668, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light {
	background-color: #fff;
}

/* line 672, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light .vod-player__control-bar {
	background-color: #e2e2e2;
	background: linear-gradient(top bottom, #e2e2e2 0%, #dbdbdb 50%, #d1d1d1 51%, #fefefe 100%);
}

/* line 678, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light .vod-player__btn {
	color: #555;
}

/* line 682, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light .vod-player__btn:hover {
	color: #222;
}

/* line 687, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light[data-running="true"] .vod-player__btn.btn--play::before,
.vod-player.t-light[data-running="true"] .vod-player__btn.btn--play::after,
.vod-player.t-light .vod-player__btn.btn--sound span::after {
	background-color: #555;
}

/* line 693, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light[data-running="true"] .vod-player__btn.btn--play:hover::before,
.vod-player.t-light[data-running="true"] .vod-player__btn.btn--play:hover::after,
.vod-player.t-light .vod-player__btn.btn--sound:hover span::after {
	background-color: #222;
}

/* line 700, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light .vod-player__btn.btn--sound span::before {
	border-right-color: #555;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player.t-light .vod-player__btn.btn--sound span::before {
	border-right-color: none;
	border-left-color: #555;
}

/* line 704, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light .vod-player__btn.btn--sound:hover span::before {
	border-right-color: #222;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player.t-light .vod-player__btn.btn--sound:hover span::before {
	border-right-color: none;
	border-left-color: #222;
}

/* line 708, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light.is-mute .vod-player__btn.btn--sound::after {
	border-bottom-color: #e2e2e2;
}

/* line 712, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light .vod-player__lang-selector {
	background-color: rgba(200, 200, 200, 0.5);
}

/* line 716, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light .vod-player__lang-selector > li {
	color: rgb(var(--color-global-negative));
}

/* line 720, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light .vod-player__lang-selector > li:hover,
.vod-player.t-light .vod-player__lang-selector > .is-active {
	background-color: rgba(255, 255, 255, 0.7);
}

/* line 725, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light .vod-player__btn.btn--lang .lang__current::after {
	border-right-color: #777;
	border-bottom-color: #777;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player.t-light .vod-player__btn.btn--lang .lang__current::after {
	border-right-color: none;
	border-left-color: #777;
}

/* line 730, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-light .vod-player__btn.btn--lang .lang__current::before {
	border-left-color: #777;
	border-top-color: #777;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player.t-light .vod-player__btn.btn--lang .lang__current::before {
	border-left-color: none;
	border-right-color: #777;
}

/* line 737, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark {
	background-color: #000;
}

/* line 741, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark .vod-player__control-bar {
	background-color: #666;
	background: linear-gradient(top bottom, #666 0%, #262626 50%, #020202 51%, #777 100%);
}

/* line 747, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark .vod-player__btn {
	color: #aaa;
}

/* line 751, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark .vod-player__btn:hover {
	color: #ddd;
}

/* line 755, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark[data-running="true"] .vod-player__btn.btn--play::before,
.vod-player.t-dark[data-running="true"] .vod-player__btn.btn--play::after,
.vod-player.t-dark .vod-player__btn.btn--sound span::after {
	background-color: #aaa;
}

/* line 761, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark[data-running="true"] .vod-player__btn.btn--play:hover::before,
.vod-player.t-dark[data-running="true"] .vod-player__btn.btn--play:hover::after,
.vod-player.t-dark .vod-player__btn.btn--sound:hover span::after {
	background-color: #ddd;
}

/* line 767, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark .vod-player__btn.btn--sound span::before {
	border-right-color: #aaa;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player.t-dark .vod-player__btn.btn--sound span::before {
	border-right-color: none;
	border-left-color: #aaa;
}

/* line 771, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark .vod-player__btn.btn--sound:hover span::before {
	border-right-color: #ddd;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player.t-dark .vod-player__btn.btn--sound:hover span::before {
	border-right-color: none;
	border-left-color: #ddd;
}

/* line 775, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark.is-mute .vod-player__btn.btn--sound::after {
	border-bottom-color: #666;
}

/* line 779, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark .vod-player__lang-selector {
	background-color: rgba(20, 20, 20, 0.5);
}

/* line 783, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark .vod-player__lang-selector > li {
	color: rgb(var(--color-global-positive));
}

/* line 787, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark .vod-player__lang-selector > li:hover,
.vod-player.t-dark .vod-player__lang-selector > .is-active {
	background-color: rgba(0, 0, 0, 0.7);
}

/* line 792, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark .vod-player__btn.btn--lang .lang__current::after {
	border-right-color: #aaa;
	border-bottom-color: #aaa;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player.t-dark .vod-player__btn.btn--lang .lang__current::after {
	border-right-color: none;
	border-left-color: #aaa;
}

/* line 797, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-player.t-dark .vod-player__btn.btn--lang .lang__current::before {
	border-left-color: #aaa;
	border-top-color: #aaa;
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-player.t-dark .vod-player__btn.btn--lang .lang__current::before {
	border-left-color: none;
	border-right-color: #aaa;
}

/* line 802, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn {
	cursor: pointer;
}

/* line 805, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn .icon, .vod-btn .vod-btn.btn--play.is-playing, .vod-btn .vod-btn.btn--play:not(.is-playing), .vod-btn .vod-btn.btn--sound.is-mute, .vod-btn .vod-btn.btn--sound:not(.is-mute) {
	display: none;
	color: rgb(var(--color-global-secondary));
}

/* line 810, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn.vod-sound .vod-sound-on.icon, .vod-btn.vod-sound .vod-sound-on.vod-btn.btn--play.is-playing, .vod-btn.vod-sound .vod-sound-on.vod-btn.btn--play:not(.is-playing), .vod-btn.vod-sound .vod-sound-on.vod-btn.btn--sound.is-mute, .vod-btn.vod-sound .vod-sound-on.vod-btn.btn--sound:not(.is-mute) {
	display: block;
}

/* line 814, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn.vod-sound-off .vod-sound-mute.icon, .vod-btn.vod-sound-off .vod-sound-mute.vod-btn.btn--play.is-playing, .vod-btn.vod-sound-off .vod-sound-mute.vod-btn.btn--play:not(.is-playing), .vod-btn.vod-sound-off .vod-sound-mute.vod-btn.btn--sound.is-mute, .vod-btn.vod-sound-off .vod-sound-mute.vod-btn.btn--sound:not(.is-mute) {
	display: block;
}

/* line 818, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn.vod-play:not(.vod-pause) .vod-button-play.icon, .vod-btn.vod-play:not(.vod-pause) .vod-button-play.vod-btn.btn--play.is-playing, .vod-btn.vod-play:not(.vod-pause) .vod-button-play.vod-btn.btn--play:not(.is-playing), .vod-btn.vod-play:not(.vod-pause) .vod-button-play.vod-btn.btn--sound.is-mute, .vod-btn.vod-play:not(.vod-pause) .vod-button-play.vod-btn.btn--sound:not(.is-mute) {
	display: block;
}

/* line 822, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn.vod-pause .vod-button-pause.icon, .vod-btn.vod-pause .vod-button-pause.vod-btn.btn--play.is-playing, .vod-btn.vod-pause .vod-button-pause.vod-btn.btn--play:not(.is-playing), .vod-btn.vod-pause .vod-button-pause.vod-btn.btn--sound.is-mute, .vod-btn.vod-pause .vod-button-pause.vod-btn.btn--sound:not(.is-mute) {
	display: block;
}

/* line 654, resources/80-themes/Saunders/fonts/_icons.scss */
.vod-btn.btn--play.is-playing:before {
	content: "\f182";
}

/* line 654, resources/80-themes/Saunders/fonts/_icons.scss */
.vod-btn.btn--play:not(.is-playing):before {
	content: "\f115";
}

/* line 654, resources/80-themes/Saunders/fonts/_icons.scss */
.vod-btn.btn--sound.is-mute:before {
	content: "\f1ba";
}

/* line 654, resources/80-themes/Saunders/fonts/_icons.scss */
.vod-btn.btn--sound:not(.is-mute):before {
	content: "\f1bb";
}

/* line 843, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn.vod-player__btn.btn--lang {
	float: unset;
	padding: 5px;
	transition: background-color .25s linear;
}

/* line 848, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn.vod-player__btn.btn--lang:hover {
	background-color: rgb(var(--color-global-secondary));
	color: rgb(var(--color-global-positive));
}

/* line 854, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn .vod-player__lang-selector {
	text-align: left;
	background-color: rgb(var(--color-global-widget));
}

/* line 7, scss/20-tools/_mixins.scss */
.is-rtl .vod-btn .vod-player__lang-selector {
	text-align: right;
}

/* line 858, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn .vod-player__lang-selector > li {
	color: rgb(var(--color-global-primary));
	background-color: transparent;
	transition: background-color .25s linear;
}

/* line 864, scss/80-themes/Saunders/60-components/vod-player.scss */
.vod-btn .vod-player__lang-selector > li:hover {
	color: rgb(var(--color-global-positive));
	background-color: rgb(var(--color-global-primary));
}

/* line 872, scss/80-themes/Saunders/60-components/vod-player.scss */
.m-article-media .vod-stage .vod-control {
	display: none;
}

/* line 878, scss/80-themes/Saunders/60-components/vod-player.scss */
.m-article-media .vod-icon {
	background: transparent;
}

/* line 885, scss/80-themes/Saunders/60-components/vod-player.scss */
.m-article-media .video_container, .m-article-media
.vod-container {
	overflow: hidden;
}

/* line 891, scss/80-themes/Saunders/60-components/vod-player.scss */
.m-article-media .vod-movie {
	max-height: 100%;
	max-width: 100%;
}

/* line 900, scss/80-themes/Saunders/60-components/vod-player.scss */
.m-article-media .video_container, .m-article-media
.vod-container, .m-article-media
.vod-movie {
	height: 100%;
}

/*# sourceMappingURL=../../../../true */